<template>
  <div
    class="radio"
    :class="{
      'radio--active': isChecked,
      'radio--disabled': disabled,
    }"
  >
    <label class="radio__container">
      <input
        class="radio__input"
        type="radio"
        :name="name"
        :value="value"
        :disabled="disabled"
        v-model="isChecked"
      />

      <div
        class="radio__container-inner"
        :class="{'radio__container-inner--card': card}"
      >
        <div class="radio__checkmark"></div>

        <div class="radio__content">
          <!-- @slot Custom label markup (bind 'label' string, 'isChecked' boolean, 'disabled' boolean -->
          <slot name="label" v-bind="{label, isChecked, disabled}">
            <div v-if="label" class="radio__label">{{ label }}</div>
          </slot>
          <!-- @slot Custom details markup (bind 'details' string -->
          <slot name="details" v-bind="{details}">
            <p v-if="details" class="radio__details">
              {{ details }}
            </p>
          </slot>
          <!-- @slot Custom description markup (bind 'description' string -->
          <slot name="description" v-bind="{description}">
            <p v-if="description" class="radio__description">
              {{ description }}
            </p>
          </slot>
        </div>
        <div v-if="image" class="radio__image">
          <img :src="image" alt="" loading="lazy" />
        </div>
      </div>
    </label>
  </div>
</template>

<script setup lang="ts">
const store = useDefaultStore();
const shopwareStore = useShopwareStore();

const props = defineProps({
  name: {
    type: String,
    default: '',
  },
  value: {
    type: [String, Object, Array, Boolean],
    default: '',
  },
  label: {
    type: String,
    default: '',
  },
  details: {
    type: String,
    default: '',
  },
  description: {
    type: String,
    default: '',
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  card: {
    type: Boolean,
    default: false,
  },
  selected: {
    type: [String, Object, Array, Boolean],
    default: '',
  },
  image: {
    type: String,
    default: '',
  },
});

const isChecked = defineModel();
</script>

<style lang="scss">
.radio {
  margin-bottom: $spacing-xs;
}

.radio__container-inner {
  display: flex;
  align-items: center;

  &--card {
    background-color: $color-white;
    border: 2px solid $color-white;
    border-radius: $radius-small;
    padding: $spacing-xs $spacing-base;
    transition: all 0.2s ease-in-out;
    justify-content: space-between;

    .radio__input:checked + & {
      // border-color: $color-green;
    }

    @media (hover: hover) {
      &:hover {
        // background: $color-light-gray;
        border-color: $color-black;
        cursor: pointer;
      }
    }
  }
}

.radio__input {
  visibility: hidden;
  position: absolute;
  z-index: -10;
}

.radio__checkmark {
  width: 0.75rem;
  height: 0.75rem;
  padding: $spacing-xxs;
  border: 2px solid $color-black;
  border-radius: 9999px;
  margin-right: $spacing-xs;
  position: relative;
  // top: $spacing-xs;

  .radio__input:checked + .radio__container-inner & {
    background: $color-white;
    border-color: $color-green;
    position: relative;

    &:before {
      content: '';
      width: 0.4rem;
      height: 0.4rem;
      border-radius: 50%;
      background: $color-green;
      @include absolute-center;
    }
  }
}

.radio__description {
  font-size: $text-xs;
  font-weight: $font-light;
}

.radio__content {
  flex: 1 0 auto;
}

.radio__image {
  width: 2.25rem;
  margin-left: $spacing-xs;
}
</style>
